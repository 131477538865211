<template>
  <div id="app">
    <v-app>
      <top-menu v-on:increment="sidebarStatus" class="d-print-none"></top-menu>
      <!-- <v-navigation-drawer v-model="drawer" fixed temporary>
        <sidebar></sidebar>
      </v-navigation-drawer> -->
      <v-content>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-content>
      <v-footer class="d-print-none" app dark>
        <v-spacer></v-spacer>
        &copy; Tokyo Medical Service 2020. Version
        {{ version }}
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import TopMenu from "./components/TopMenu.vue";
import { version } from "../package.json";
// import sidebar from "./components/sidebar.vue";

export default {
  name: "App",
  components: {
    TopMenu,
    // sidebar,
  },
  data: () => ({
    db: {},
    drawer: false,
    version,
  }),
  created() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("msie") !== -1) {
      window.alert(
        "この機能はInternet Explorerでは使用する事はできません。\n Google Chrome,safariまたはFireFoxでお試しください。"
      );
    }
    this.$store.commit("getDefaultQuestions");
  },
  methods: {
    sidebarStatus() {
      this.drawer = !this.drawer;
    },
  },
};
</script>
