<template>
  <div>
    <v-app-bar fixed color="teal" dark>
      <v-toolbar-title>Front</v-toolbar-title>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    pushSide() {
      this.$emit("increment");
    },
  },
};
</script>
<style></style>
