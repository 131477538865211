import firebase from "firebase";
import Vue from "vue";
import VueQrcodeReader from "vue-qrcode-reader";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import vuetify from "./plugins/vuetify";

// import VueHtmlToPaper from "vue-html-to-paper";

import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyCZ6CIJI3-E47_n-PQD6bUzNCaCGneXE7o",
  authDomain: "front-9ca93.firebaseapp.com",
  databaseURL: "https://front-9ca93.firebaseio.com",
  projectId: "front-9ca93",
  storageBucket: "front-9ca93.appspot.com",
  messagingSenderId: "591427920829",
  appId: "1:591427920829:web:6a777f15fca66de89aa8d4",
  measurementId: "G-9Z8Z3TLLKK",
};

Vue.use(VueQrcodeReader);

firebase.initializeApp(firebaseConfig);
firebase.analytics();

new Vue({
  router,
  store,
  vuetify,

  // vuetify,
  render: (h) => h(App),
}).$mount("#app");
